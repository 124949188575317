import logo from './assets/logo.png';
import style from "./App.module.scss";

import {FaPhone} from "react-icons/fa";
import {Section, FeaturesList} from "./components/Components";
import MobileApp from "./platforms/MobileApp";
import AutoApp from "./platforms/AutoApp";
import TVApp from "./platforms/TVApp";

function App() {
  return (
    <div className="App">
      <header className={style.navbar}>
        <div className={style.headerInner}>
          <img className={style.logo} src={logo} alt="Amos by Appidea" />
          <div className={style.headerInnerButtons}>
            <a href="#contact">
              <FaPhone size={28}/>
            </a>
            {/*<a href="https://studio.useamos.com" className={style.headerButton}>*/}
            {/*  Sign up / Sign in*/}
            {/*</a>*/}
          </div>
        </div>
      </header>

      <div className={style.hero}>
        <div className={style.heroInner}>
          {/*<video loop autoPlay muted src={require('./assets/istockphoto-1312489908-640_adpp_is.mp4')} className={style.heroCoverImg} />*/}
          <img src={require('./assets/bg-topp.jpg')} className={style.heroCoverImg} />
          <div className={style.heroOverlay} />
          <div className={style.heroContent}>
            <div className={style.heroContentTexts}>
              <h2>Analogue radio rocks. But it's worth to enter new era of media content delivery.</h2>
              <h1>Get the most comprehensive, cross-platform player app for Your radio.</h1>
            </div>
          </div>
        </div>
      </div>

      <div className={style.page}>

        <div className={style.container}>
          <p className={style.features}>
            <p style={{fontWeight: 'bold'}}>
              In Appidea, we love the radio.
            </p>
            <p>
              That's why we decided to create the solution, which will
              let You <b>create Your own music app for Your listeners.</b>
            </p>
            <p>
              You don't have to relay on external streaming platforms like Spotify, Tidal - create Your platform, with Your monetization rules.
            </p>

            Amos. offers You the: mobile app, car app, TV app and plugins for Your radio's website. Scroll down to learn more.
          </p>
        </div>

        <MobileApp />
        <AutoApp />
        <TVApp />

        <Section header={['One way to reach Your content on any platform. Introduce', 'One Playback Experience.']}>
          <img src={require('./wireframes.png')} className={style.cover2} alt="Illustration: Amos"/>

          <p className={style.features}>
            <FeaturesList>
              <li>
                centralised database of Your online podcasts and live streams
              </li>
              <li>
                the player communicates directly with Your media database created in Amos.Studio. This way You got all the analytics and media management in one place.
              </li>
              <li>
                Amos.Studio provides an easy-to-use interface. Organise the navigation over Your media using drag-and-drop,
                as You do with the folders on Your PC. Deliver this hierarchy directly to Your end users’ app.
                You can go with that, or use our REST API to easily bind Amos into Your company flow.
              </li>
              <li>
                Engage Your users with side functions: <b>user account, news feed, notifications, in-app activities.</b>
              </li>
              <li>
                Improve Your radio presence in the car infotainment systems - our applications comes with a support
                for <b>Android Auto™</b> and <b>Apple™ CarPlay™</b> technologies.
                Our applications are ready for <b>Android™ Automotive</b>.
              </li>
            </FeaturesList>
          </p>
        </Section>

        <Section header={['Drag, drop, create in Amos.Studio.', 'Your team have a new place to be.']}>
          <img src={require('./studio.png')} className={style.cover2} style={{marginTop: '40px'}} alt="Illustration: Amos Studio"/>

          <p className={style.features}>
            Amos.Studio provides an easy-to-use interface.
            Organise the navigation over Your media using drag-and-drop, as You do with the folders on Your PC.
            Deliver this hierarchy directly to Your end users’ app.
            <br /><br />

            Have already some global solution to manage the media?
            It is possible to gain access to the features available on Amos.Studio
            via REST API. Ask us to get more informations.
          </p>
        </Section>

        <Section header={['Monetization of the content?', 'We respect Your vision in this matter.']}>
          <p className={style.features}>
            We know, You are the content owner and You are the expert in Your content monetization.
            In Amos, we are open to Your monetization model.
            Contact us to see our pricing plans, we will find the best match for Your business.
          </p>
        </Section>

        <section id="contact" className={style.contact}>
          <div className={style.pageInner + ' ' + style.bottom}>
            <section className={style.contactForm}>
              <h2>
                Sounds good? Let's schedule a demo.<br/>
              </h2>

              <p style={{marginBottom: '30px'}}>
                Contact us:
              </p>

              <div className={style.contactRow}>
                <div className={style.contactCol} style={{marginBottom: '10px'}}>
                  <a href="mailto:sales@useamos.com"><b>sales@useAmos.com</b></a>
                </div>
                <div className={style.contactCol}>
                  <div style={{marginBottom: '10px'}}>
                    Sales:
                  </div>

                  <div>
                    <a href="tel:+48669055170">
                      Poland: <b>+48 669 055 170</b>
                    </a>
                  </div>

                  <div>
                    <a href="tel:+420775843070">
                      Czech Republic: <b>+420 775 843 070</b>
                    </a>
                  </div>
                </div>
              </div>

            </section>
          </div>

          <div className={style.copy}>
            <div className={style.copyCol}>
              <b>© 2023 Appidea.pl</b>
              <br />
              A Software Development company<br />
              Warsaw, Poland<br />
              NIP: 956 228 39 82
            </div>
            <div className={style.copyCol}>
              Appidea - official sponsor<br />
              <b>of Radiodays 2023</b><br />
              in Prague, Czech Republic<br />
              <a href="https://radiodayseurope.com">RadiodaysEurope.com</a>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
    ;
}

export default App;
