import React, {useRef} from 'react';
import classNames from 'classnames';
import {FaAndroid, FaApple} from "react-icons/fa";
import {useScroll, useTransform, motion} from "framer-motion";
import style from "../App.module.scss";
import mobileStyle from "./MobileApp.module.scss";
import Features from "../components/Features";
import {AnimatedHeader} from "../components/Components";

const features = [
  {text: 'News feed'},
  {text: 'Search audio content by query or by category'},
  {text: 'Control playback from notification'},
  {text: 'Control playback from the lock screen'},
  {text: 'Control playback from the wearables'},
  {text: 'Player seamlessly integrates with Android Auto / Car Play'}
];

const platforms = [
  {
    text: 'iOS',
    icon: FaApple,
  },
  {
    text: 'Android',
    icon: FaAndroid,
  }
];

const MobileApp = () => {
  const appRef = useRef();

  const {scrollYProgress} = useScroll({
    target: appRef,
    offset: ["end end", "start start"]
  });

  const multip = useTransform(scrollYProgress, x => x * -300);

  return (
    <div className={classNames(style.container, style.mobileApp)}>
      <div className={style.platformDemo}>
        <div ref={appRef} className={mobileStyle.window}>
          <img src={require('../assets/mobile_top.png')}
               className={classNames(mobileStyle.windowTop, mobileStyle.appElements)}/>
          <img src={require('../assets/mobile_bar.png')}
               className={classNames(mobileStyle.windowBottom, mobileStyle.appElements)}/>
          <motion.img src={require('../assets/mobile_content.png')}
                      style={{y: multip}}
                      className={classNames(mobileStyle.windowContent, mobileStyle.appElements)}/>
        </div>
      </div>
      <div className={style.platformDescription}>
        <AnimatedHeader className={style.platformDescriptionHeading}>
          <h2>Stunning and complete</h2>
          <h1>mobile app.</h1>
        </AnimatedHeader>

        <div className={style.platformDescriptionText}>
          <p>
            Plays your content using convinient player and content search,<br />Entertains Your listeners with top news, stories with our News Feed.
          </p>
        </div>

        <div className={style.platformList}>
          <Features features={features} platforms={platforms} />
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
