import React, {useRef} from 'react';
import classNames from 'classnames';
import style from "../App.module.scss";
import {useScroll} from "framer-motion";
import {FaAndroid, FaTv} from "react-icons/fa";
import Features from "../components/Features";
import tvStyle from "../platforms/TVApp.module.scss";
import {AnimatedHeader} from "../components/Components";

const features = [
  {text: 'All Your content available using the TV`s Remote Control'},
  {text: 'PIP mode - allow users to preview the TV channels or other input when Your radio plays in the background'},
];

const platforms = [
  {
    text: 'Samsung Tizen',
    icon: FaTv,
    ongoing: true
  },
  {
    text: 'Android',
    icon: FaAndroid,
    ongoing: true
  }
];

const TVApp = () => {
  const appRef = useRef();

  const {scrollYProgress} = useScroll({
    target: appRef,
    offset: ["end end", "start start"]
  });

  return (
    <div className={classNames(style.container, style.tvApp)}>
      <div className={style.platformDemo}>
        <img className={tvStyle.window} src={require('../assets/mocks/tv player.png')}/>
      </div>
      <div className={classNames(style.platformDescription, tvStyle.platformDescription)}>
        <AnimatedHeader className={style.platformDescriptionHeading}>
          <h2>TV is not only about movies.</h2>
          <h1>Play Your radio on modern TVs.</h1>
        </AnimatedHeader>

        <div className={style.platformDescriptionText}>
          <p>
            Play Your content on Your listener's TV using the Amos capability to create Smart TV app. We support Tizen
            platform, and support for other platforms is on the way.
          </p>
        </div>

        <div className={style.platformList}>
          <Features features={features} platforms={platforms}/>
        </div>
      </div>
    </div>
  );
};

export default TVApp;
