import React from 'react';
import {FaCheck} from "react-icons/fa";

import style from "./Features.module.scss";
import classNames from "classnames";

const Features = ({platforms, features}) => {
  return (
    <div>
      <ul className={classNames(style.list, style.platforms)}>
        {platforms.map(({text, icon: Icon, ongoing}, key) => (
          <li key={key}>
            <Icon size={22} />
            {ongoing && (
              <div className={style.comingSoon}>Coming soon</div>
            )}
            {text} support
          </li>
        ))}
      </ul>

      <ul className={classNames(style.list, style.features)}>
        {features.map(({text}, key) => (
          <li key={key}>
            <FaCheck size={16} />
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Features;
