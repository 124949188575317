import style from "./Components.module.scss";
import {motion} from "framer-motion";

const variants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 1
    }
  },
  hidden: {
    x: -100,
    opacity: 0
  }
};

export const AnimatedHeader = ({className, children}) => (
  <motion.div className={className} variants={variants} whileInView="visible" initial="hidden" viewport={{once: true}}>
    {children}
  </motion.div>
);

export const Section = ({header, children}) => (
  <section className={style.pageInner}>
    <AnimatedHeader className={style.heading}>
      <h2>{header[0]}</h2>
      <h1>{header[1]}</h1>
    </AnimatedHeader>

    {children}
  </section>
);

export const FeaturesList = ({children}) => (
  <ul className={style.featuresList}>
    {children}
  </ul>
)
