import React, {useEffect, useRef} from 'react';
import classNames from 'classnames';
import {stagger, useAnimate, useInView} from "framer-motion";
import autoStyle from "./AutoApp.module.scss";
import {
  FaBroadcastTower,
  FaHeadphones,
  FaNewspaper,
  FaPhone,
  FaLocationArrow,
  FaApple, FaAndroid
} from "react-icons/fa";
import style from "../App.module.scss";
import Features from "../components/Features";
import {AnimatedHeader} from "../components/Components";

const features = [
  {text: 'Browse Your online streams and podcasts using the Car interface'},
  {text: 'Allows user to create own playlist of favorite tracks'},
  {text: 'Continue playback after leaving the car'},
  {text: 'Control playback from steering wheel'},
  // {text: 'Use Siri to navigate'},
  {text: 'Manage the content hierarchy by API or Amos.Studio'}
];

const platforms = [
  {
    text: 'iOS',
    icon: FaApple,
  },
  {
    text: 'Android',
    icon: FaAndroid,
  }
];

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });

const executeTapAnim = (animate, element, focusColor, on) => {
  if (on) {
    animate([
      [
        element,
        { background: focusColor, color: '#FFF' },
        { duration: 0.3 }
      ],
      [
        element,
        { background: '#000', color: focusColor },
        { duration: 0.3 }
      ]
    ]);
  } else {
    animate(
      element,
      { background: '#000', color: '#FFF' },
      { duration: 0.6 }
    );
  }
}

const EpisodeTile = ({image, text}) => (
  <li>
    <i>
      <img src={image} />
    </i>
    <span>{text}</span>
  </li>
);

const EpisodeList = ({image, text}) => (
  <li>
    <i>
      <img src={image} />
    </i>
    <span>{text}</span>
  </li>
);

const MobileApp = () => {
  const app = useRef();
  const firstScreenStart = useInView(app, {margin: '0% 0% -25% 0%'});
  const secondScreenStart = useInView(app, {margin: '0% 0% -70% 0%'});

  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      "#amos-autolist li",
      firstScreenStart
        ? { x: 0 }
        : { x: -1000 },
      {
        duration: 1,
        delay: firstScreenStart ? staggerMenuItems : 0,
        ease: 'easeInOut'
      }
    );

    executeTapAnim(animate, '#amos-tabnews', '#4bade1', firstScreenStart);
  }, [firstScreenStart, animate]);

  useEffect(() => {
    animate(
      "#amos-autotiles li",
      secondScreenStart
        ? { x: 0 }
        : { x: -1000 },
      {
        duration: 1,
        delay: secondScreenStart ? staggerMenuItems : 0,
        ease: 'easeInOut'
      }
    );

    animate(
      "#amos-autolist li",
      secondScreenStart
        ? { x: 1000 }
        : { x: 0 },
      {
        duration: 1,
        delay: firstScreenStart ? staggerMenuItems : 0,
        ease: 'easeInOut'
      }
    );

    executeTapAnim(animate, '#amos-tabstream', '#4bade1', secondScreenStart);
    executeTapAnim(animate, '#amos-tabnews', '#4bade1', false);
  }, [secondScreenStart, animate]);

  return (
    <div className={classNames(style.container, style.autoApp)}>
      <div className={style.platformDemo}>
        <div ref={app} className={autoStyle.window}>
          <div className={autoStyle.windowSidebar}>
            <i className={classNames(autoStyle.windowSidebarItem, autoStyle.windowSidebarItemActive)}>
              <img src={require('../assets/mobile_icon.png')} alt="icon" />
            </i>
            <i className={classNames(autoStyle.windowSidebarItem, autoStyle.windowSidebarItemInactive)}>
              <FaLocationArrow size={25} />
            </i>
            <i className={classNames(autoStyle.windowSidebarItem, autoStyle.windowSidebarItemInactive)}>
              <FaPhone size={25} />
            </i>
            <i className={classNames(autoStyle.windowSidebarItem, autoStyle.windowSidebarItemInactive)}>
              <FaHeadphones size={25} />
            </i>
          </div>

          <div className={autoStyle.tabs} ref={scope}>
            <div className={autoStyle.tabsContent}>
              <div id="amos-tabnews" className={autoStyle.tabsItem}>
                <FaNewspaper size={30} />
                <span>News</span>
              </div>
              <div id="amos-tabstream" className={autoStyle.tabsItem}>
                <FaBroadcastTower size={30} />
                <span>Stream</span>
              </div>
              <div className={autoStyle.tabsItem}>
                <FaHeadphones size={30} />
                <span>Podcasts</span>
              </div>
            </div>

            <div className={autoStyle.windowMainContent}>
              <ul id="amos-autolist" className={autoStyle.list}>
                <EpisodeList text="The Creative Mindset" image={require('../assets/mocks/podcast-1.jpg')} />
                <EpisodeList text="History of the Rock and Roll" image={require('../assets/mocks/podcast-2.jpg')} />
                <EpisodeList text="The Space and the Stars" image={require('../assets/mocks/podcast-3.jpg')} />
                <EpisodeList text="Corporate Social Responsibility" image={require('../assets/mocks/podcast-1.jpg')} />
                <EpisodeList text="New Show 2" image={require('../assets/mocks/podcast-2.jpg')} />
                <EpisodeList text="New Show 3" image={require('../assets/mocks/podcast-3.jpg')} />
              </ul>
              <ul id="amos-autotiles" className={autoStyle.tiles}>
                <EpisodeTile text="Flow FM Rock" image={require('../assets/mocks/logo-rock.png')} />
                <EpisodeTile text="Flow FM Easy" image={require('../assets/mocks/logo-easy.png')} />
                <EpisodeTile text="Flow FM Culture" image={require('../assets/mocks/logo-culture.png')} />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(style.platformDescription, autoStyle.platformDescription)}>
        <AnimatedHeader className={style.platformDescriptionHeading}>
          <h2>Convenient and cool</h2>
          <h1>auto app.</h1>
        </AnimatedHeader>

        <div className={style.platformDescriptionText}>
          <p>
            Be prepared for automotive revolution in the radio industry.
            Apps created using the Amos solution are capable to be displayed in the infotainment solutions
            like Android Auto, Apple CarPlay, and on the car infotainment systems built on Android Automotive OS.
          </p>

          <p>
            <a href="https://www.android.com/auto/compatibility/" target="_blank">Click here to see the cars compatible with Android Auto</a>.
          </p>

          {/*<p>*/}
          {/*  Voice commands (Siri / Google Assistant) are supported.*/}
          {/*</p>*/}
        </div>

        <div className={style.platformList}>
          <Features platforms={platforms} features={features} />
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
